<template>
  <div class="systemInfo wr">
    <div class="info-a wr f-cb">
      <div class="left">
        <div class="pinfo-container wr swiper-container-horizontal swiper-container-android">
        <div class="swiper-wrapper">
        <div class="swiper-slide swiper-slide-active">
          <img :src="require('@/img/managementSystem.png')" alt="">
        </div>
        </div>
        </div>
        </div>
        <div class="right ">
        <div class="tit-60 mc hnb">智慧河湖河道监测管理系统</div>
        <div class="tit-18 t1 hnb">
        <p style="line-height: 1.75em; text-align: justify;">
          <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
            专为河湖生态保护和水质管理设计。融合了传感技术、数据分析和地理信息系统（ GIS ），系统通过布设在河湖中的传感器，实
            时收集水质、流量、水位和生态数据。通过先进通信技术，数据即时传输至中央平台，利用数据分析工具为水资源管理提供科学决
            策支持，提升河湖管理效率和生态保护水平。
          </span>
        </p>
        <p style="line-height: 1.75em;"><br></p>
        </div>
      </div>
    </div>
    <div class="view">
      <div class="info-b wr">
        <div class="tit-48 mc lh1 hnb">产品特点</div>
        <div class="list f-cb">
          <div class="li"> 
            <div>
              <div class="icon">
                <img :src="require('@/img/productFeatures-icon.png')" alt="综合水质监测">
              </div>
              <div class="tit-24 t1 hnb">综合水质监测</div>
            </div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统监测河湖中的水质参数，同时评估生物指标和生态条件，全面了解水体健康，保护生态多样性。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="河流动态模拟">
            </div>
            <div class="tit-24 t1 hnb">河流动态模拟</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统结合流量、水位数据和GIS技术，模拟河流动态，预测洪水路径和影响区域，为制定防洪措施提供依据。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="污染源追踪">
            </div>
            <div class="tit-24 t1 hnb">污染源追踪</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统利用先进算法，追踪定位污染源，评估污染扩散风险，并实时通知管理部门应对。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="多维度数据融合">
            </div>
            <div class="tit-24 t1 hnb">多维度数据融合</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统融合实时数据、历史数据、气象信息和地理信息，提供全面的决策支持，增强管理的前瞻性。
                </span>
              </p>
            </div>
          </div>
          <div class="li">
            <div class="icon">
              <img :src="require('@/img/productFeatures-icon.png')" alt="智能警报与自动调度">
            </div>
            <div class="tit-24 t1 hnb">智能警报与自动调度</div>
            <div class="tit-16 t2 text-justify">
              <p style="line-height: 1.75em; text-align: justify;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  系统监测到异常事件（如水质突变或危险水位）时自动发出警报，并联动水闸等设施进行应急响应，减轻潜在损害。
                </span>
              </p>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
.mc {
    color: #0041c3;
}
.lh1 {
    line-height: 1;
}
.tit-48 {
    line-height: 1.8;
    font-size: 48px;
}
// web端样式
.systemInfo {
  background-image: url(../../../img/bg3.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding-bottom: 4.5rem;
  .info-a {
    padding-top: 7.38rem;
    padding-bottom: 6rem;
    padding-left: 9rem;
    .left {
      float: left;
      width: 44.8%;
      .pinfo-container {
          overflow: hidden;
      }
    }
    .right {
        float: left;
        width: 55.2%;
        padding: 32px 190px 0 89px;
      .mc {
        border-bottom: 1px solid rgba(0, 65, 195, .2);
        line-height: 1;
        padding-bottom: 2.5rem;
        position: relative;
      }
      .mc::after {
        content: "";
        width: 60px;
        height: 6px;
        background: #0041C3;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      .t1 {
        color: #000000;
        margin-top: 3.31rem;
      }
    }
  }
  .view {
    width: 100%;
    padding: 0 190px;
    .info-b {
      background-color: #fff;
      padding: 76px 118px;
      margin-top: 20px;
      .list {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .li {
            width: calc(39.5%);
            // width: calc((100% - 115px) / 2);
            margin-right: 115px;
            padding: 40px 0;
            border-bottom: 1px solid rgba(207, 214, 229, 1);
            .icon {
              display: inline-block;
              vertical-align: middle;
              margin-bottom: 0.63rem;
            }
            .t1 {
                display: inline-block;
                margin-top: 1.25rem;
                margin-bottom: 1rem;
                margin-left: 0.8rem;
                position: relative;
            }
        }
      }
    }
  }
}
// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>