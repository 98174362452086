var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"systemInfo wr"},[_c('div',{staticClass:"info-a wr f-cb"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"pinfo-container wr swiper-container-horizontal swiper-container-android"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide swiper-slide-active"},[_c('img',{attrs:{"src":require('@/img/managementSystem.png'),"alt":""}})])])])]),_vm._m(0)]),_c('div',{staticClass:"view"},[_c('div',{staticClass:"info-b wr"},[_c('div',{staticClass:"tit-48 mc lh1 hnb"},[_vm._v("产品特点")]),_c('div',{staticClass:"list f-cb"},[_c('div',{staticClass:"li"},[_c('div',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"综合水质监测"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("综合水质监测")])]),_vm._m(1)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"河流动态模拟"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("河流动态模拟")]),_vm._m(2)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"污染源追踪"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("污染源追踪")]),_vm._m(3)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"多维度数据融合"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("多维度数据融合")]),_vm._m(4)]),_c('div',{staticClass:"li"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require('@/img/productFeatures-icon.png'),"alt":"智能警报与自动调度"}})]),_c('div',{staticClass:"tit-24 t1 hnb"},[_vm._v("智能警报与自动调度")]),_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"tit-60 mc hnb"},[_vm._v("智慧河湖河道监测管理系统")]),_c('div',{staticClass:"tit-18 t1 hnb"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 专为河湖生态保护和水质管理设计。融合了传感技术、数据分析和地理信息系统（ GIS ），系统通过布设在河湖中的传感器，实 时收集水质、流量、水位和生态数据。通过先进通信技术，数据即时传输至中央平台，利用数据分析工具为水资源管理提供科学决 策支持，提升河湖管理效率和生态保护水平。 ")])]),_c('p',{staticStyle:{"line-height":"1.75em"}},[_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统监测河湖中的水质参数，同时评估生物指标和生态条件，全面了解水体健康，保护生态多样性。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统结合流量、水位数据和GIS技术，模拟河流动态，预测洪水路径和影响区域，为制定防洪措施提供依据。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统利用先进算法，追踪定位污染源，评估污染扩散风险，并实时通知管理部门应对。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统融合实时数据、历史数据、气象信息和地理信息，提供全面的决策支持，增强管理的前瞻性。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tit-16 t2 text-justify"},[_c('p',{staticStyle:{"line-height":"1.75em","text-align":"justify"}},[_c('span',{staticStyle:{"font-family":"微软雅黑, \"Microsoft YaHei\""}},[_vm._v(" 系统监测到异常事件（如水质突变或危险水位）时自动发出警报，并联动水闸等设施进行应急响应，减轻潜在损害。 ")])])])
}]

export { render, staticRenderFns }